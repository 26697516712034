import styled from 'styled-components'

const FooterContent = styled.div`
  background-color: black;
  padding: 4rem 0;

  @media screen and (min-width: 1024px) {
    padding: 40px 48px;
  }

  @media screen and (min-width: 1920px) {
    padding: 70px 375px;
  }
`

const Content = styled.div`
  max-width: 1920px;
  margin: 0 auto;
  color: #fff;

  .divRowOne {
    margin: 0 auto;
    border-bottom: 1px solid #fff;
    width: 60%;
  }

  .divRowTwo {
    margin: 0 auto;
    border-bottom: 1px solid #fff;
    width: 60%;
  }

  @media (min-width: 660px) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    padding: 1rem;

    .divRowOne {
      width: 1px;
      border-left: 1px solid #fff;
      min-height: 140px;
      border-bottom: none;
    }

    .divRowTwo {
      display: none;
    }
  }

  @media (min-width: 1024px) {
    .divRowTwo {
      display: block;
      width: 1px;
      border-left: 1px solid #fff;
      min-height: 140px;
      border-bottom: none;
    }
  }
`

const SocialNetworks = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  max-width: 287px;
  margin: 0 auto;
  padding-bottom: 20px;

  h1 {
    text-align: center;
    color: white;
    font-size: 1.4rem;
  }

  .social-icons {
    display: flex;
    column-gap: 16px;
    align-items: center;
  }

  .social-icons img {
    width: 16px;
    height: 16px;
  }

  @media (min-width: 660px) {
    width: 300px;

    h1 {
      text-align: left;
    }

    .social-icons {
      margin: 0;
    }
  }

  @media (min-width: 1024px) {
    width: 262px;

    flex-direction: column;

    h1 {
      font-size: 2.5rem;
      line-height: 37.5px;
      text-align: center;
      margin-bottom: 16px;
    }

    .social-icons {
      width: 100%;
      display: flex;
      justify-content: center;
      column-gap: 32px;
    }

    .social-icons img {
      width: 24px;
      height: 24px;
    }
  }
`

const ContentInfo = styled.div`
  width: 300px;
  margin: 15px auto;

  p {
    text-align: center;
    font-size: 1.6rem;
  }

  .infoEdgeTitle {
    text-align: center;
    font-size: 1.6rem;
    font-weight: 800;
  }

  .contactItem {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .contactItem p {
    margin: 2px 0;
    font-weight: 800;
  }

  .contactItem img {
    width: 1.8rem;
    height: 1.8rem;
    margin-right: 1.5rem;
  }

  .privacyPolicy {
    font-weight: 700;
    font-size: 1.4rem;
    text-decoration-line: underline;
    color: white;
    margin-top: 1rem;
    font-weight: 700;
    cursor: pointer;
  }

  @media (min-width: 768px) {
    margin: 0 auto;
    width: 300px;

    .infoEdgeTitle {
      margin-top: 0;
    }
  }
`

const ContentLogos = styled.div`
  margin: 15px auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-center;
  align-items: center;
  width: 300px;

  .logoEdge img {
    margin-top: 1rem;
    width: 135px;
  }

  .smartWayLogoContainer img {
    width: 160px;
  }

  @media (min-width: 660px) {
    width: 450px;
    justify-content: space-between;

    .logoEdge img {
      margin-left: 55px;
      width: 150px;
    }

    .smartWayLogoContainer img {
      margin-right: 25px;
      width: 185px;
    }
  }

  @media (min-width: 1024px) {
    width: 350px;

    .logoEdge img {
      margin-left: 0;
    }

    .smartWayLogoContainer img {
      margin-right: 0;
    }
  }
`

export { FooterContent, Content, SocialNetworks, ContentInfo, ContentLogos }
