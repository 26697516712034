//  libraries
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Link } from 'gatsby'

//  components

//  styles
import {
  FooterContent,
  Content,
  SocialNetworks,
  ContentInfo,
  ContentLogos
} from './footer.style'

const Footer = () => {
  const Data = useStaticQuery(graphql`
    {
      strapiLayout {
        footer {
          content {
            email
            name
            number
          }
          linksIcons {
            facebook
            instagram
            linkedin
            twitter
          }
        }
        logoEdgeWhite {
          publicURL
        }
        iconFacebook {
          publicURL
        }
        iconLinkedin {
          publicURL
        }
        iconInstagram {
          publicURL
        }
        iconTwiter {
          publicURL
        }
        smartWayLogo {
          publicURL
        }
      }
      mailIcon: file(relativePath: { eq: "email_footer-75.svg" }) {
        publicURL
      }
      phoneIcon: file(relativePath: { eq: "phone_footer-76.svg" }) {
        publicURL
      }
    }
  `)

  const {
    footer,
    logoEdgeWhite,
    iconInstagram,
    iconLinkedin,
    iconTwiter,
    iconFacebook,
    smartWayLogo
  } = Data.strapiLayout
  const { mailIcon, phoneIcon } = Data

  return (
    <FooterContent>
      <Content>
        <SocialNetworks>
          <h1>Follow Us</h1>
          <div className="social-icons">
            <a href={footer.linksIcons.instagram}>
              <img src={iconInstagram.publicURL} alt="logo" />
            </a>
            <a href={footer.linksIcons.facebook}>
              <img src={iconFacebook.publicURL} alt="logo" />
            </a>
            <a href={footer.linksIcons.linkedin}>
              <img src={iconLinkedin.publicURL} alt="logo" />
            </a>
            <a href={footer.linksIcons.twitter}>
              <img src={iconTwiter.publicURL} alt="logo" />
            </a>
          </div>
        </SocialNetworks>
        <div className="divRowOne" />
        <ContentInfo>
          <p className="infoEdgeTitle">Our Locations</p>
          <p>Chicago | Phoenix</p>
          <div className="contactEdge">
            <div className="contactItem">
              <img id="mailFooter" alt="icon" src={mailIcon.publicURL} />
              <p>{`${footer.content.email}`} </p>
            </div>
            <div className="contactItem">
              <img src={phoneIcon.publicURL} id="phoneFooter" alt="icons" />{' '}
              <p>{`${footer.content.number}`}</p>
            </div>
            <div className="contactItem">
              <Link className="privacyPolicy" to="/privacy-policy">
                Privacy Policy
              </Link>
            </div>
          </div>
        </ContentInfo>
        <div className="divRowTwo" />
        <ContentLogos>
          <div className="logoEdge">
            <img src={logoEdgeWhite.publicURL} alt="logo" />
          </div>
          <div className="smartWayLogoContainer">
            <img src={smartWayLogo.publicURL} alt="logo" />
          </div>
        </ContentLogos>
      </Content>
    </FooterContent>
  )
}

// Footer style props
Footer.propTypes = {}

export default Footer
